import React, { useState } from 'react';
import './LoginScreen.css';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Import Firestore database
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      alert('Please enter both email and password.');
      return;
    }

    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Get the user data from Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Check if the user has the role of "admin"
        if (userData.role === 'admin') {
          navigate('/admin'); // Redirect admins to the admin dashboard
        } else {
          navigate('/driver-onboarding'); // Redirect non-admins to driver onboarding
        }
      } else {
        alert('No user data found.');
      }
    } catch (error) {
      alert(`Login Failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <img src="/blitz_logo.png" alt="BLITZ Logo" className="logo" />
      <h1>Login</h1>
      <input 
        type="email" 
        placeholder="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        className="input-field"
      />
      <input 
        type="password" 
        placeholder="Password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        className="input-field"
      />
      <button 
        onClick={handleLogin} 
        className={`login-button ${loading ? 'disabled' : ''}`} 
        disabled={loading}
      >
        {loading ? 'Logging in...' : 'Login'}
      </button>
      <p className="footer-text">
        Don't have an account?{' '}
        <span 
          className="register-link" 
          onClick={() => navigate('/register')}
        >
          Register
        </span>
      </p>
    </div>
  );
};

export default LoginScreen;
