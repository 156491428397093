// DriverNavigationPage.js

import React, { useState, useEffect, useRef } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure your Firebase configuration is correct
import './DriverNavigationPage.css';

const DriverNavigationPage = () => {
  // State Variables
  const [currentLocation, setCurrentLocation] = useState(null);
  const [route, setRoute] = useState(null);
  const [distance, setDistance] = useState('');
  const [eta, setEta] = useState('');
  const [directions, setDirections] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isAtDestination, setIsAtDestination] = useState(false);
  const [rideData, setRideData] = useState(null);
  const [pickupAddress, setPickupAddress] = useState('');
  const [dropoffAddress, setDropoffAddress] = useState('');
  const [mapLoaded, setMapLoaded] = useState(false);
  const [currentInstruction, setCurrentInstruction] = useState('');
  const [isExpanded, setIsExpanded] = useState(false); // State for bottom sheet

  // Refs
  const mapRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Google Maps API Key
  const googleApiKey = 'AIzaSyDVTRR4e9wo3V1dxI9SmlIYIoqAoELfhrc'; // Keep your API Key

  // Proximity Threshold in meters to detect arrival at destination
  const proximityThreshold = 50;

  // Extract rideID from query parameters and fetch ride data
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const rideID = queryParams.get('rideID');

    if (rideID && !rideData) {
      const fetchRideData = async () => {
        try {
          const rideDocRef = doc(db, 'fastTrackRides', rideID);
          const rideDoc = await getDoc(rideDocRef);
          if (rideDoc.exists()) {
            const data = rideDoc.data();
            // Convert coordinates to { lat, lng } format
            const pickupLocation = {
              lat: data.pickupLocation.latitude,
              lng: data.pickupLocation.longitude,
            };
            const dropoffLocation = {
              lat: data.dropoffLocation.latitude,
              lng: data.dropoffLocation.longitude,
            };
            setRideData({
              rideId: rideID,
              pickupLocation: pickupLocation,
              dropoffLocation: dropoffLocation,
              fare: parseFloat(data.fare) || 0,
            });

            // Convert coordinates to addresses
            const pickupAddr = await reverseGeocode(pickupLocation);
            const dropoffAddr = await reverseGeocode(dropoffLocation);
            setPickupAddress(pickupAddr);
            setDropoffAddress(dropoffAddr);
          } else {
            console.error('No such ride document!');
          }
        } catch (error) {
          console.error('Error fetching ride data:', error);
        }
      };
      fetchRideData();
    }
  }, [location.search, rideData]);

  // Reverse Geocoding to get addresses from coordinates
  const reverseGeocode = async (location) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${googleApiKey}`
      );
      const data = await response.json();
      if (data.status === 'OK') {
        return data.results[0].formatted_address;
      } else {
        console.error('Geocoding error:', data.status);
        return 'Address not found';
      }
    } catch (error) {
      console.error('Reverse Geocoding failed:', error);
      return 'Address not found';
    }
  };

  // Get the driver's current location
  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const currentPos = { lat: latitude, lng: longitude };
        setCurrentLocation(currentPos);

        // Pan the map to the new location if map is loaded and not manually panned
        if (mapRef.current) {
          mapRef.current.panTo(currentPos);
        }

        // Check if the driver is near the destination
        if (rideData && rideData.dropoffLocation) {
          const distanceToDestination = calculateDistance(
            currentPos,
            rideData.dropoffLocation
          );

          if (distanceToDestination <= proximityThreshold) {
            setIsAtDestination(true);
          }
        }
      },
      (error) => {
        console.error('Error getting current position:', error);
      },
      { enableHighAccuracy: true, maximumAge: 1000, timeout: 5000 } // Added timeout for better handling
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, [rideData]);

  // Fetch the route, distance, and ETA
  useEffect(() => {
    if (rideData && currentLocation && mapLoaded) {
      const directionsService = new window.google.maps.DirectionsService();
      const request = {
        origin: currentLocation,
        destination: rideData.dropoffLocation,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          setRoute(result);
          setDistance(result.routes[0].legs[0].distance.text);
          setEta(result.routes[0].legs[0].duration.text);
          setDirections(result.routes[0].legs[0].steps);
          setCurrentInstruction(result.routes[0].legs[0].steps[0].instructions);
        } else {
          console.error(`Error fetching directions: ${status}`);
        }
      });
    }
  }, [rideData, currentLocation, mapLoaded]);

  // Function to calculate the distance between two coordinates (in meters)
  const calculateDistance = (point1, point2) => {
    const R = 6371000; // Radius of Earth in meters
    const dLat = ((point2.lat - point1.lat) * Math.PI) / 180;
    const dLng = ((point2.lng - point1.lng) * Math.PI) / 180;
    const lat1 = (point1.lat * Math.PI) / 180;
    const lat2 = (point2.lat * Math.PI) / 180;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) *
        Math.sin(dLng / 2) *
        Math.cos(lat1) *
        Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  // Update ride status to 'Completed' when driver reaches destination
  useEffect(() => {
    if (isAtDestination && rideData?.rideId) {
      const rideDocRef = doc(db, 'fastTrackRides', rideData.rideId);
      updateDoc(rideDocRef, {
        rideStatus: 'Completed',
        completionTimestamp: new Date(),
      })
        .then(() => {
          console.log('Ride status updated to Completed');
          navigate(`/ride-completion?rideID=${rideData.rideId}`);
        })
        .catch((error) => {
          console.error('Error updating ride status:', error);
        });
    }
  }, [isAtDestination, rideData, navigate]);

  // Function to update current instruction based on driver's location
  useEffect(() => {
    if (!route || !currentLocation || directions.length === 0) return;

    const currentPos = new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng);
    const step = directions[currentStepIndex];
    const stepEndLocation = new window.google.maps.LatLng(
      step.end_location.lat(),
      step.end_location.lng()
    );

    const distanceToStep = window.google.maps.geometry.spherical.computeDistanceBetween(currentPos, stepEndLocation);

    if (distanceToStep < proximityThreshold) {
      if (currentStepIndex < directions.length - 1) {
        setCurrentStepIndex(currentStepIndex + 1);
        setCurrentInstruction(directions[currentStepIndex + 1].instructions);
      } else {
        // Reached final destination
        setIsAtDestination(true);
      }
    }
  }, [currentLocation, directions, currentStepIndex, route]);

  // Map onLoad handler to set mapLoaded to true and store map instance
  const handleMapLoad = (mapInstance) => {
    mapRef.current = mapInstance; // Store the map instance in ref
    setMapLoaded(true);
  };

  // Function to toggle bottom sheet
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="driver-navigation-page">
      <LoadScript
        googleMapsApiKey={googleApiKey}
        libraries={['geometry']}
      >
        <div className="navigation-container">
          {/* Directions Section at the Top */}
          <div className="directions-section">
            <h4>Current Directions:</h4>
            <p
              dangerouslySetInnerHTML={{ __html: currentInstruction }}
            ></p>
          </div>

          {/* Map Section */}
          <div className="map-container">
            <GoogleMap
              // Removed the center prop to prevent re-centering on every render
              zoom={14}
              mapContainerClassName="map"
              options={{
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: true,
              }}
              onLoad={handleMapLoad}
            >
              {rideData && (
                <>
                  <Marker position={rideData.pickupLocation} label="P" />
                  <Marker position={rideData.dropoffLocation} label="D" />
                  {route && (
                    <DirectionsRenderer
                      directions={route}
                      options={{
                        suppressMarkers: true,
                        polylineOptions: { strokeColor: '#8C1627', strokeWeight: 4 },
                      }}
                    />
                  )}
                </>
              )}
              {currentLocation && (
                <Marker position={currentLocation} label="You" />
              )}
            </GoogleMap>
          </div>

          {/* Ride Info Bottom Sheet */}
          {rideData && (
            <div
              className={`ride-info ${isExpanded ? 'expanded' : ''}`}
              onClick={toggleExpand}
            >
              <div className="ride-info-content">
                {/* Minimized View */}
                <div className="ride-details">
                  <p>
                    <strong>ETA:</strong> <span style={{ color: '#00FF00' }}>{eta}</span>
                  </p>
                  <p>
                    <strong>Distance:</strong> {distance}
                  </p>
                  <p>
                    <strong>Fare:</strong> ${rideData.fare.toFixed(2)}
                  </p>
                  <p>
                    <strong>Arrival Time:</strong> {/* Optionally, you can calculate and display the arrival time */}
                    {eta}
                  </p>
                </div>

                {/* Expanded View */}
                {isExpanded && (
                  <div className="additional-details">
                    <p>
                      <strong>Pickup Address:</strong> {pickupAddress}
                    </p>
                    <p>
                      <strong>Dropoff Address:</strong> {dropoffAddress}
                    </p>
                    <p>
                      <strong>Your Cut:</strong> ${(rideData.fare * 0.8).toFixed(2)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Control Buttons */}
          <div className="control-buttons">
            {!isAtDestination && rideData && (
              <button
                className="complete-ride-button"
                onClick={() => setIsAtDestination(true)}
              >
                Complete Ride
              </button>
            )}
          </div>
        </div>
      </LoadScript>
    </div>
  );
};

export default DriverNavigationPage;
