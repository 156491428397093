// src/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

// Firebase configuration (replace with your actual credentials)
const firebaseConfig = {
  apiKey: 'AIzaSyCNSsPpIAz3BiKL2MzZaJmMfrORA1Ev-1U',
  authDomain: 'blitz-1b7d2.firebaseapp.com',
  projectId: 'blitz-1b7d2',
  storageBucket: 'blitz-1b7d2.appspot.com',
  messagingSenderId: '92340281807',
  appId: '1:92340281807:android:028f9f1dccc1fcf56532ba',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize and export Storage

// Optionally set session persistence to keep users logged in across sessions
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    console.log('Firebase session persistence enabled');
  })
  .catch((error) => {
    console.error('Error setting Firebase persistence:', error);
  });

// **Export initialized instances**
export { app, auth, db, storage };
