// OnboardingPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation for URL parameters
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { auth, db, storage } from '../firebase'; // Import Firebase services
import {
  collection,
  addDoc,
  serverTimestamp,
  getDoc,
  doc,
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import './Onboarding.css';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { TextractClient, AnalyzeDocumentCommand } from '@aws-sdk/client-textract';

// Ensure AWS region and credentials are loaded from environment variables
const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

// Check if AWS credentials are available
if (!AWS_REGION || !AWS_ACCESS_KEY_ID || !AWS_SECRET_ACCESS_KEY) {
  console.error('AWS configuration is missing. Please check your environment variables.');
}

// Initialize AWS Textract Client with credentials
const textractClient = new TextractClient({
  region: AWS_REGION,
  credentials: {
    accessKeyId: AWS_ACCESS_KEY_ID,
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
  },
});

// Load your actual Stripe public key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); // Ensure this variable is set in your environment

// Helper function to convert file to base64
const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]); // Base64 string
    reader.onerror = (error) => reject(error);
  });
};

// Helper function to extract card number from text
const extractCardNumber = (text) => {
  const cardNumberMatch = text.replace(/\s+/g, '').match(/\b\d{13,19}\b/);
  return cardNumberMatch ? cardNumberMatch[0] : null;
};

// Helper function to extract expiration date from text
const extractExpirationDate = (text) => {
  const lines = text.split('\n');
  let expDate = null;

  for (let line of lines) {
    line = line.trim();
    // Match patterns for expiration labels, considering different placements and abbreviations
    let expMatch = line.match(/(EXP|EXPIRES|EXPIRATION|EXPIRATION DATE|VALID UNTIL|VALID THRU|THRU|VALID TO|VALIDITY).*?(\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}|\d{4})/i);
    if (expMatch) {
      expDate = expMatch[2]; // Capture the expiration date
      break;
    }
    // Match date formats directly, allowing for different separators and lengths
    let dateMatch = line.match(/(\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}|\d{1,2}[\/\-]\d{4}|\d{4})/);
    if (dateMatch) {
      expDate = dateMatch[1];
      break;
    }
  }

  if (expDate) {
    let parsedDate;
    if (/^\d{4}$/.test(expDate)) {
      parsedDate = new Date(`${expDate}-12-31`); // YYYY format
    } else if (/^\d{1,2}[\/\-]\d{4}$/.test(expDate)) {
      // MM/YYYY format
      const [month, year] = expDate.split(/[\/\-]/);
      parsedDate = new Date(`${year}-${month}-01`);
    } else if (/^\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}$/.test(expDate)) {
      // MM/DD/YYYY or MM/DD/YY format
      const [month, day, year] = expDate.split(/[\/\-]/);
      const fullYear = year.length === 2 ? '20' + year : year;
      parsedDate = new Date(`${fullYear}-${month}-${day}`);
    } else {
      parsedDate = new Date(expDate);
    }
    return !isNaN(parsedDate.getTime()) ? parsedDate : null;
  }
  return null;
};

// Helper function to match names in different orders
const nameMatchesInDifferentOrder = (licenseText, inputName) => {
  const licenseNames = licenseText.toLowerCase().split(/\s+/);
  const inputNames = inputName.toLowerCase().split(/\s+/);
  return inputNames.every((name) => licenseNames.includes(name));
};

// Function to analyze document using AWS Textract
const analyzeDocument = async (file) => {
  const base64File = await toBase64(file);
  const params = {
    Document: {
      Bytes: Buffer.from(base64File, 'base64'),
    },
    FeatureTypes: ['FORMS', 'TABLES'], // Added 'TABLES' to improve detection of structured data
  };

  try {
    const command = new AnalyzeDocumentCommand(params);
    const response = await textractClient.send(command);
    return response;
  } catch (error) {
    console.error('Error analyzing document:', error);
    throw new Error(`Error analyzing document: ${error.message}`);
  }
};

// Phone number formatting
const formatPhoneNumber = (phone) => {
  if (!phone) return '';
  const cleaned = ('' + phone).replace(/\D/g, '');
  if (cleaned.startsWith('1') && cleaned.length === 11) {
    return `+${cleaned}`;
  } else if (cleaned.length === 10) {
    return `+1${cleaned}`;
  } else if (phone.startsWith('+')) {
    return phone;
  } else {
    return `+${cleaned}`;
  }
};

// Main Onboarding Form Component
const OnboardingForm = () => {
  const [rideID, setRideID] = useState('');
  const [pickupLat, setPickupLat] = useState('');
  const [pickupLng, setPickupLng] = useState('');
  const [dropoffLat, setDropoffLat] = useState('');
  const [dropoffLng, setDropoffLng] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [licenseFile, setLicenseFile] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // For navigation to DriverNavigationPage
  const location = useLocation(); // To access URL parameters

  useEffect(() => {
    // Parse URL parameters to extract QR code data
    const queryParams = new URLSearchParams(location.search);
    const rideIDParam = queryParams.get('rideID');
    const pickupLatParam = queryParams.get('pickupLat');
    const pickupLngParam = queryParams.get('pickupLng');
    const dropoffLatParam = queryParams.get('dropoffLat');
    const dropoffLngParam = queryParams.get('dropoffLng');

    if (rideIDParam) {
      setRideID(rideIDParam);
      setPickupLat(pickupLatParam || '');
      setPickupLng(pickupLngParam || '');
      setDropoffLat(dropoffLatParam || '');
      setDropoffLng(dropoffLngParam || '');
    } else {
      alert('Invalid ride information. Please ensure you have scanned a valid QR code.');
      navigate('/'); // Redirect to home if rideID is missing
    }

    // Initialize reCAPTCHA when the component mounts
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log('reCAPTCHA solved');
          },
          'expired-callback': () => {
            console.log('reCAPTCHA expired');
          },
        },
        auth
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error('Error rendering reCAPTCHA:', error);
      });
    }
  }, [location.search, navigate]);

  // Function to send SMS verification code
  const sendVerificationCode = async () => {
    if (!phone) {
      alert('Please enter a valid phone number.');
      return;
    }

    const formattedPhone = formatPhoneNumber(phone);

    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhone,
        appVerifier
      );
      window.confirmationResult = confirmationResult;
      alert('Verification code sent.');
    } catch (error) {
      console.error('Error sending verification code:', error);
      alert('Error sending verification code. Please try again.');
      // Reset reCAPTCHA in case of error
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    }
  };

  // Handle phone verification
  const verifyPhoneNumber = async () => {
    if (!verificationCode) {
      alert('Please enter the verification code.');
      return;
    }

    try {
      const confirmationResult = window.confirmationResult;
      await confirmationResult.confirm(verificationCode);
      setIsPhoneVerified(true);
      alert('Phone number verified!');
    } catch (error) {
      console.error('Error verifying code:', error);
      alert('Invalid code. Please try again.');
    }
  };

  // Extract license details using AWS Textract
  const extractLicenseDetails = async (file) => {
    setLoading(true);
    try {
      const textractData = await analyzeDocument(file);
      const text = textractData.Blocks.filter((block) => block.BlockType === 'LINE')
        .map((block) => block.Text)
        .join('\n');

      const expirationDate = extractExpirationDate(text);
      const nameMatches = nameMatchesInDifferentOrder(text, name);

      if (nameMatches && expirationDate) {
        const currentDate = new Date();
        if (expirationDate >= currentDate) {
          alert('Driver’s license verification successful.');
          return true;
        } else {
          alert('Driver’s license is expired.');
          return false;
        }
      } else {
        alert('Name does not match the driver’s license.');
        return false;
      }
    } catch (error) {
      console.error('Error analyzing license:', error);
      alert('Failed to analyze the driver’s license. Please try again.');
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setLoading(true);

    if (!stripe || !elements || !isPhoneVerified || !termsAccepted || !licenseFile) {
      alert('Please ensure all required fields are filled and a driver’s license is scanned.');
      setIsSubmitting(false);
      setLoading(false);
      return;
    }

    const isLicenseValid = await extractLicenseDetails(licenseFile);
    if (!isLicenseValid) {
      setIsSubmitting(false);
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { name, email, phone },
    });

    if (error) {
      alert(error.message);
      setIsSubmitting(false);
      setLoading(false);
    } else {
      try {
        const licenseFileUrl = await uploadToStorage(licenseFile, 'licenses');
        await addDoc(collection(db, 'fastTrackDrivers'), {
          name,
          email,
          phone,
          licenseFileUrl,
          paymentMethodID: paymentMethod.id,
          rideID, // Associate driver with the ride
          isOnboarded: true,
          onboardTimestamp: serverTimestamp(),
        });

        alert('Onboarding completed successfully!');
        // Navigate to DriverNavigationPage with rideID as URL parameter
        navigate(`/drivernavigationpage?rideID=${rideID}`);
      } catch (err) {
        alert('Error during onboarding. Please try again.');
        console.error('Error adding document:', err);
      } finally {
        setIsSubmitting(false);
        setLoading(false);
      }
    }
  };

  // Open file selector for scanning driver's license
  const openFileSelectorForLicense = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.capture = 'environment';
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        setLicenseFile(file);
        alert('Driver’s license photo selected!');
      }
    };
    fileInput.click();
  };

  return (
    <form className="onboarding-form" onSubmit={handleSubmit}>
      <h2>Driver Onboarding</h2>

      {/* Name Input */}
      <div className="form-section">
        <label>Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter name as it appears on driver's license"
          required
        />
      </div>

      {/* Email Input */}
      <div className="form-section">
        <label>Email</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      {/* Phone Verification */}
      <div className="form-section">
        <label>Phone Number</label>
        <div className="phone-verification-container">
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            placeholder="+1XXXXXXXXXX"
          />
          <button
            type="button"
            className="send-code-btn subtle-btn"
            onClick={sendVerificationCode}
            disabled={isSubmitting}
          >
            Send Code
          </button>
        </div>
        <div className="phone-verification-container">
          <input
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            disabled={isSubmitting}
          />
          <button
            type="button"
            className="verify-btn subtle-btn"
            onClick={verifyPhoneNumber}
            disabled={isSubmitting}
          >
            Verify Phone
          </button>
        </div>
        {/* reCAPTCHA container */}
        <div id="recaptcha-container"></div>
      </div>

      {/* Debit Card Information */}
      <div className="form-section">
        <label>Debit Card Information</label>
        <div className="card-inputs">
          <CardNumberElement className="card-element" options={{ style: cardStyle }} />
          <CardExpiryElement className="card-element" options={{ style: cardStyle }} />
          <CardCvcElement className="card-element" options={{ style: cardStyle }} />
        </div>
      </div>

      {/* Scan Driver's License */}
      <div className="form-section">
        <label>Scan Driver's License</label>
        <button
          type="button"
          className="scan-license-btn subtle-btn"
          onClick={openFileSelectorForLicense}
          disabled={isSubmitting}
        >
          Upload License
        </button>
        {licenseFile && <p>Driver's license image selected.</p>}
      </div>

      {/* Terms and Conditions */}
      <div className="form-section terms-link">
        <input
          type="checkbox"
          checked={termsAccepted}
          onChange={() => setTermsAccepted(!termsAccepted)}
        />
        <label>
          I have reviewed and accept the{' '}
          <a href="/tcinstablitzdriver" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </a>
          .
        </label>
      </div>

      {/* Loading Indicator */}
      {loading && (
        <div className="loading-bar">
          <div className="progress"></div>
          Processing...
        </div>
      )}

      {/* Submit Button */}
      {isPhoneVerified && termsAccepted && (
        <button type="submit" className="submit-btn" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Complete Onboarding'}
        </button>
      )}
    </form>
  );
};

// Stripe Card Element Styling
const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#a0aec0',
      },
    },
    invalid: {
      color: '#e53e3e',
      iconColor: '#e53e3e',
    },
  },
};

// Function to upload files to Firebase Storage
const uploadToStorage = async (file, folder) => {
  try {
    const storageRef = ref(storage, `${folder}/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error('Error uploading file to storage:', error);
    throw error;
  }
};

const OnboardingPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <OnboardingForm />
    </Elements>
  );
};

export default OnboardingPage;
