import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase'; // Firebase configuration
import { collection, onSnapshot, updateDoc, doc, deleteDoc } from 'firebase/firestore'; // Firestore functions
import { Link } from 'react-router-dom'; // To navigate between dashboards
import './AdminDashboard.css'; // Admin panel CSS

const AdminDashboard = () => {
  const [applications, setApplications] = useState([]); // Store driver applications
  const [loading, setLoading] = useState(true); // Track loading state
  const [adminNotes, setAdminNotes] = useState({}); // Store admin notes for each application
  const [isAdmin, setIsAdmin] = useState(false); // Store admin status
  const [searchTerm, setSearchTerm] = useState(''); // Store search term
  const [selectedApplications, setSelectedApplications] = useState([]); // Store selected applications for bulk delete

  // Check if the current user has the admin claim
  const checkAdminStatus = async (user) => {
    const token = await user.getIdTokenResult();
    if (token.claims.admin) {
      setIsAdmin(true);
      return true;
    }
    return false;
  };

  // Real-time listener to fetch driver applications from Firestore
  useEffect(() => {
    const fetchData = async () => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const isAdminUser = await checkAdminStatus(user);

          if (isAdminUser) {
            const unsubscribe = onSnapshot(
              collection(db, 'drivers'), // Listen to the 'drivers' collection in Firestore
              (snapshot) => {
                const driverApplications = snapshot.docs.map((doc) => ({
                  id: doc.id, // Document ID
                  ...doc.data(), // All other fields from the document
                }));
                setApplications(driverApplications);
                setLoading(false); // Disable loading state
              },
              (error) => {
                console.error('Error fetching driver applications:', error);
                setLoading(false); // Disable loading state if there's an error
              }
            );
            return () => unsubscribe(); // Clean up the listener when the component unmounts
          } else {
            console.error('You do not have admin privileges');
            setLoading(false);
          }
        } else {
          console.error('No user is signed in');
          setLoading(false);
        }
      });
    };

    fetchData();
  }, []);

  // Dynamic search filtering as you type
  const filteredApplications = applications.filter((app) =>
    app.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (app.email && app.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Approve driver application
  const handleApprove = async (id) => {
    try {
      const driverRef = doc(db, 'drivers', id); // Reference to the driver document in Firestore
      await updateDoc(driverRef, {
        status: 'approved', // Set status to 'approved'
        adminNotes: adminNotes[id] || '', // Store admin notes if provided
      });
      setApplications((prev) =>
        prev.map((app) => (app.id === id ? { ...app, status: 'approved' } : app)) // Update local state
      );
    } catch (error) {
      console.error('Error approving application:', error);
    }
  };

  // Reject driver application
  const handleReject = async (id) => {
    try {
      const driverRef = doc(db, 'drivers', id); // Reference to the driver document in Firestore
      await updateDoc(driverRef, {
        status: 'rejected', // Set status to 'rejected'
        adminNotes: adminNotes[id] || '', // Store admin notes if provided
      });
      setApplications((prev) =>
        prev.map((app) => (app.id === id ? { ...app, status: 'rejected' } : app)) // Update local state
      );
    } catch (error) {
      console.error('Error rejecting application:', error);
    }
  };

  // Request more information from the driver
  const handleRequestMoreInfo = async (id) => {
    try {
      const driverRef = doc(db, 'drivers', id); // Reference to the driver document in Firestore
      await updateDoc(driverRef, {
        status: 'requested_more_info', // Set status to 'requested_more_info'
        adminNotes: adminNotes[id] || 'Please provide additional information.', // Default note if none provided
      });
      setApplications((prev) =>
        prev.map((app) =>
          app.id === id ? { ...app, status: 'requested_more_info' } : app
        ) // Update local state
      );
    } catch (error) {
      console.error('Error requesting more info:', error);
    }
  };

  // Handle changes in admin notes
  const handleNotesChange = (id, notes) => {
    setAdminNotes((prevState) => ({ ...prevState, [id]: notes })); // Update admin notes for the corresponding driver
  };

  // Handle application selection for bulk actions
  const handleSelectApplication = (id) => {
    setSelectedApplications((prev) =>
      prev.includes(id) ? prev.filter((appId) => appId !== id) : [...prev, id]
    );
  };

  // Bulk delete selected applications
  const handleBulkDelete = async () => {
    try {
      for (const id of selectedApplications) {
        await deleteDoc(doc(db, 'drivers', id)); // Delete each selected application from Firestore
      }
      setApplications((prev) => prev.filter((app) => !selectedApplications.includes(app.id)));
      setSelectedApplications([]); // Clear the selected applications state
    } catch (error) {
      console.error('Error deleting applications:', error);
    }
  };

  // Handle admin logout
  const handleLogout = () => {
    auth.signOut().then(() => {
      console.log('User logged out');
    }).catch((error) => {
      console.error('Error logging out:', error);
    });
  };

  // If data is still being loaded, display a loading message
  if (loading) {
    return <div>Loading applications...</div>;
  }

  // If the user is not an admin, show an error message
  if (!isAdmin) {
    return <div>You do not have permission to access this data.</div>;
  }

  // Render the admin dashboard with the driver applications
  return (
    <div className="admin-dashboard">
      <header className="dashboard-header">
        <h1>Admin Dashboard</h1>
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </header>

      <div className="admin-controls">
        <input
          type="text"
          placeholder="Search by name or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Trigger search dynamically
          className="search-input"
        />
        <button onClick={handleBulkDelete} className="bulk-delete-button" disabled={selectedApplications.length === 0}>
          Delete Selected ({selectedApplications.length})
        </button>
        {/* Add button to navigate to InstaBlitz Dashboard */}
        <Link to="/admin/instablitz" className="instablitz-button">
          View InstaBlitz Drivers
        </Link>
      </div>

      <table className="applications-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Full Name</th>
            <th>Date of Birth</th>
            <th>Address</th>
            <th>License Number</th>
            <th>Vehicle Make</th>
            <th>Vehicle Model</th>
            <th>Vehicle Year</th>
            <th>SSN</th>
            <th>License Front</th>
            <th>License Back</th>
            <th>Registration Image</th>
            <th>Insurance Image</th>
            <th>Status</th>
            <th>Consent</th>
            <th>Admin Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredApplications.map((application) => (
            <tr key={application.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedApplications.includes(application.id)}
                  onChange={() => handleSelectApplication(application.id)}
                />
              </td>
              <td>{application.fullName || 'No Data'}</td>
              <td>{application.dob || 'No Data'}</td>
              <td>{application.address || 'No Data'}</td>
              <td>{application.licenseNumber || 'No Data'}</td>
              <td>{application.vehicleMake || 'No Data'}</td>
              <td>{application.vehicleModel || 'No Data'}</td>
              <td>{application.vehicleYear || 'No Data'}</td>
              <td>{application.ssn || 'N/A'}</td>
              <td>
                {application.licenseFront ? (
                  <a href={application.licenseFront} target="_blank" rel="noopener noreferrer">
                    View Front
                  </a>
                ) : (
                  'No Image'
                )}
              </td>
              <td>
                {application.licenseBack ? (
                  <a href={application.licenseBack} target="_blank" rel="noopener noreferrer">
                    View Back
                  </a>
                ) : (
                  'No Image'
                )}
              </td>
              <td>
                {application.registrationImage ? (
                  <a href={application.registrationImage} target="_blank" rel="noopener noreferrer">
                    View Registration
                  </a>
                ) : (
                  'No Image'
                )}
              </td>
              <td>
                {application.insuranceImage ? (
                  <a href={application.insuranceImage} target="_blank" rel="noopener noreferrer">
                    View Insurance
                  </a>
                ) : (
                  'No Image'
                )}
              </td>
              <td className={`status ${application.status}`}>{application.status || 'Pending'}</td>
              <td>{application.consent ? 'Yes' : 'No'}</td>
              <td>
                <textarea
                  placeholder="Enter admin notes here..."
                  value={adminNotes[application.id] || ''}
                  onChange={(e) => handleNotesChange(application.id, e.target.value)}
                />
              </td>
              <td>
                <div className="actions-buttons">
                  <button
                    className="approve-button"
                    onClick={() => handleApprove(application.id)}
                    disabled={application.status === 'approved'}
                  >
                    Approve
                  </button>
                  <button
                    className="reject-button"
                    onClick={() => handleReject(application.id)}
                    disabled={application.status === 'rejected'}
                  >
                    Reject
                  </button>
                  <button
                    className="info-button"
                    onClick={() => handleRequestMoreInfo(application.id)}
                    disabled={application.status === 'requested_more_info'}
                  >
                    Request More Info
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;
