import React, { useState, useEffect } from 'react';
import './OnePager.css';
import { Link } from 'react-router-dom';
import { FaBolt, FaQrcode, FaHandshake, FaCar, FaCheckCircle, FaMobileAlt, FaMapMarkerAlt } from 'react-icons/fa';

// Countdown Calculation
const calculateCountdown = (launchDate) => {
  const now = new Date().getTime();
  const distance = new Date(launchDate).getTime() - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

const OnePager = () => {
  const [ridesPerDay, setRidesPerDay] = useState(10);
  const [averageFare, setAverageFare] = useState(15);
  const [blitzDailyEarnings, setBlitzDailyEarnings] = useState(0);
  const [uberDailyEarnings, setUberDailyEarnings] = useState(0);
  const [lyftDailyEarnings, setLyftDailyEarnings] = useState(0);
  const [blitzMonthlyEarnings, setBlitzMonthlyEarnings] = useState(0);
  const [uberMonthlyEarnings, setUberMonthlyEarnings] = useState(0);
  const [lyftMonthlyEarnings, setLyftMonthlyEarnings] = useState(0);

  const [countdown, setCountdown] = useState(calculateCountdown('2024-10-20'));

  useEffect(() => {
    const hamburger = document.querySelector('.hamburger');
    const navMenu = document.querySelector('.main-nav');

    const toggleMenu = () => {
      navMenu.classList.toggle('active');
    };

    hamburger.addEventListener('click', toggleMenu);

    const interval = setInterval(() => {
      setCountdown(calculateCountdown('2024-10-20'));
    }, 1000);

    return () => {
      hamburger.removeEventListener('click', toggleMenu);
      clearInterval(interval);
    };
  }, []);

  const formatNumber = (number) => number.toLocaleString();

  const calculateEarnings = () => {
    const blitzCommission = 0; // Blitz is commission-free during the promo period
    const uberCommission = 0.25; // 25% commission
    const lyftCommission = 0.20; // 20% commission

    const dailyEarnings = ridesPerDay * averageFare;

    const blitzDaily = dailyEarnings - dailyEarnings * blitzCommission;
    const uberDaily = dailyEarnings - dailyEarnings * uberCommission;
    const lyftDaily = dailyEarnings - dailyEarnings * lyftCommission;

    setBlitzDailyEarnings(blitzDaily);
    setUberDailyEarnings(uberDaily);
    setLyftDailyEarnings(lyftDaily);

    setBlitzMonthlyEarnings(blitzDaily * 30);
    setUberMonthlyEarnings(uberDaily * 30);
    setLyftMonthlyEarnings(lyftDaily * 30);
  };

  return (
    <div className="one-pager">
      {/* Minimalistic Banner with Countdown */}
      <div className="countdown-banner">
        <p>
          We're launching soon! 
          <span> {countdown.days}d {countdown.hours}h {countdown.minutes}m {countdown.seconds}s </span>
          until the big launch on October 20th, 2024.
        </p>
      </div>

      <header className="hero-section">
        <img src="/blitz_logo.png" alt="BLITZ Logo" className="logo" />
        <div className="hamburger">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <nav className="main-nav nav-menu">
          <a href="#how-it-works" className="nav-link">How It Works</a>
          <a href="#about" className="nav-link">About</a>
          <a href="#drive" className="nav-link">Drive</a>
          <Link to="/login" className="nav-link">Login</Link>
        </nav>
        <div className="content">
          <div className="text-content left-aligned">
            <h1>Two Ways to Ride with Blitz</h1>
            <p>
              Use the Blitz app to request a ride or generate a QR code within the app to get a ride on the spot from any driver you see.
            </p>
            <div className="cta-buttons">
              <Link to="/register" className="btn">Sign Up to Ride</Link>
              <Link to="/register?type=driver" className="btn">Sign Up to Drive</Link>
            </div>
          </div>
          <img src="/retromain.png" alt="Retro Main" className="main-image" />
        </div>
      </header>

      {/* How It Works Section */}
      <section id="how-it-works" className="how-it-works-section">
        <h2>How It Works</h2>
        <div className="steps">
          <div className="step">
            <FaMobileAlt className="icon" />
            <h3>Open the Blitz App</h3>
            <p>Launch the app to request a ride or generate a QR code for an instant ride.</p>
          </div>
          <div className="step">
            <FaQrcode className="icon" />
            <h3>Choose Your Option</h3>
            <p>
              Within the app, either request a ride or generate a QR code to catch a ride with any driver you see—even if they're not registered yet.
            </p>
          </div>
          <div className="step">
            <FaHandshake className="icon" />
            <h3>Connect with Any Driver</h3>
            <p>
              Show your in-app QR code to any driver. They can scan it and get onboarded instantly through the app, connecting you both for the ride.
            </p>
          </div>
          <div className="step">
            <FaCar className="icon" />
            <h3>Enjoy the Ride</h3>
            <p>Sit back and relax while the driver takes you to your destination safely and efficiently.</p>
          </div>
        </div>
        <div className="how-it-works-cta">
          <h3>Experience the future of ridesharing.</h3>
          <Link to="/register" className="btn cta-btn">Get Started with Blitz</Link>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="about-section">
        <div className="about-content">
          <h2>About <span className="highlight">BLITZ</span></h2>
          <p>
            BLITZ is transforming the way people move. Our mission is to provide a seamless, efficient, and safe transportation experience. Whether you're a rider looking for a quick trip across town or a driver seeking to maximize your earnings, BLITZ connects you to what matters most.
          </p>
        </div>
      </section>

      {/* Earnings Comparison Section */}
      <section id="earnings-comparison" className="earnings-comparison-section">
        <div className="comparison-content">
          <h2>Compare Your Earnings</h2>
          <p>See how much more you could make with <span className="highlight">Blitz</span>, commission-free, compared to Uber and Lyft.</p>
          
          <div className="comparison-inputs">
            <div className="input-group">
              <label>Rides Per Day</label>
              <input 
                type="number" 
                value={ridesPerDay} 
                onChange={(e) => setRidesPerDay(e.target.value)} 
              />
            </div>
            <div className="input-group">
              <label>Average Fare ($)</label>
              <input 
                type="number" 
                value={averageFare} 
                onChange={(e) => setAverageFare(e.target.value)} 
              />
            </div>
            <button className="calculate-btn" onClick={calculateEarnings}>Calculate Earnings</button>
          </div>

          <div className="comparison-results">
            <div className="result-group highlight blitz-card">
              <h3>Blitz Earnings</h3>
              <div className="result-box">
                <p><span>Daily:</span> ${formatNumber(blitzDailyEarnings.toFixed(2))}</p>
                <p><span>Monthly:</span> ${formatNumber(blitzMonthlyEarnings.toFixed(2))}</p>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${(blitzDailyEarnings / 500) * 100}%` }}></div>
                </div>
              </div>
            </div>
            <div className="result-group">
              <h3>Uber Earnings</h3>
              <div className="result-box">
                <p><span>Daily:</span> ${formatNumber(uberDailyEarnings.toFixed(2))}</p>
                <p><span>Monthly:</span> ${formatNumber(uberMonthlyEarnings.toFixed(2))}</p>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${(uberDailyEarnings / 500) * 100}%` }}></div>
                </div>
              </div>
            </div>
            <div className="result-group">
              <h3>Lyft Earnings</h3>
              <div className="result-box">
                <p><span>Daily:</span> ${formatNumber(lyftDailyEarnings.toFixed(2))}</p>
                <p><span>Monthly:</span> ${formatNumber(lyftMonthlyEarnings.toFixed(2))}</p>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${(lyftDailyEarnings / 500) * 100}%` }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action for Drivers */}
      <section id="drive" className="cta-driver-section">
        <div className="driver-content">
          <h2>Become a Driver</h2>
          <ul className="benefits-list">
            <li><span className="checkmark">✔</span> Keep all your commission for the first 3 months</li>
            <li><span className="checkmark">✔</span> Extend the commission-free period by 15 days for each driver you refer</li>
            <li><span className="checkmark">✔</span> Set your own schedule and earn on your terms</li>
          </ul>
          <Link to="/register?type=driver" className="btn primary-btn">Start Driving</Link>
        </div>
      </section>

      <footer>
        <p>Contact us: info@blitz.com | 123-456-7890</p>
        <div className="footer-links">
          <a href="#">Privacy Policy</a>
          <Link to="/tos">Terms of Service</Link>
          <a href="#">Follow us on Social Media</a>
        </div>
      </footer>
    </div>
  );
};

export default OnePager;
